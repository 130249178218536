import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer.tsx";
import Navbar from "../../component/Navbar.tsx";
import Pagination from "../../component/Pagination.tsx";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const categoryList = [
  {
    text: "study tour",
    href: "/activity_category/123",
    imgUrl: "image/boarding.png",
  },
  {
    text: "考察活動",
    href: "#",
    imgUrl: "image/travel.png",
  },
  {
    text: "探索家基地",
    href: "#",
    imgUrl: "image/explore.png",
  },
];

type CategoryDataType = {
  text: string;
  href: string;
  imgUrl: string;
};

const NUMPAGEITEM = 6;

export default function ActivityCategoryTest() {
  const [dataList, setDataList] = useState<CategoryDataType[] | null>(null);
  const [currPage, setCurrPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    async function init() {
      let result = await loadData(currPage);
      setTotalPage(result.result.total);
    }
    init();
  }, []);

  function handlePageChange(newPage: number) {
    setCurrPage(newPage);
    loadData(newPage);
  }

  async function loadData(newPage: number) {
    const res = await fetch(
      `https://yja.p360tec.com/api/content/getList-article-client?pages=${newPage}&size=${NUMPAGEITEM}&type=ACTIVITY_CATEGORY`
    );
    const result = await res.json();
    //console.log(result);
    if (result.code !== "2000") return;

    const rawArticleList = result.result.objects?.[0] ?? [];
    let newDataList = rawArticleList.map((e) => {
      return {
        text: e.title,
        imgUrl: e.cover,
        href: `/activity_category/${e.a_id}`,
      };
    });

    setDataList(newDataList);
    return result;
  }

  if (dataList != null)
    return (
      <>
        <Navbar />
        <main className="mx-auto flex w-4/5 flex-col gap-6">
          <div className="mb-6 flex justify-center text-4xl font-bold">
            {t("activityCategory")}
          </div>
          <div className="grid grid-cols-1 gap-3 lg:grid-cols-3">
            {dataList.map((e) => (
              <ActivityCard text={e.text} imgUrl={e.imgUrl} href={e.href} />
            ))}
          </div>
          <Pagination
            currPage={currPage}
            totalPage={totalPage}
            onPageChange={handlePageChange}
          />
        </main>
        <Footer />
      </>
    );
}

function ActivityCard({ imgUrl, text, href }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center gap-3">
      <img
        src={imgUrl}
        className="h-56 w-full object-contain object-center"
        alt="activity_image"
      />
      <p className="text-lg">{text}</p>
      <Link to={href} className="btn bg-red-700 font-semibold text-white">
        {t("learnMore")}
      </Link>
    </div>
  );
}
