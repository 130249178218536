import React from "react";
import Footer from "../../component/Footer.tsx";
import Navbar from "../../component/Navbar.tsx";
import OurService from "./components/OurService.tsx";
import OurPartner from "./components/OurPartner.tsx";
import ActivityHistory from "./components/ActivityHistory.tsx";
import LatestNews from "./components/LatestNews.tsx";
import Gallery from "./components/Gallery.tsx";

function MainTest() {
  return (
    <>
      <Navbar />
      <main className="mx-auto my-10 flex max-w-7xl flex-col items-center gap-6">
        <Gallery />
        <OurService />
        <OurPartner />
        <LatestNews />
        <ActivityHistory />
      </main>
      <Footer />
    </>
  );
}

export default MainTest;
