import React, { useEffect, useState } from "react";
import ListItem from "./ListItem.tsx";
import {
  CoreServicesCategory,
  CoreServicesDataType,
} from "../../../lib/type-info/core-services.ts";
import Navbar from "../../../component/Navbar.tsx";
import Footer from "../../../component/Footer.tsx";

function PageTemplate({
  title,
  subtitle,
  type,
}: {
  title: string;
  subtitle: string;
  type: CoreServicesCategory;
}) {
  const [data, setData] = useState<CoreServicesDataType[]>([]);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    const res = await fetch(
      `https://yja.p360tec.com/api/content/getList-core-service-client?pages=1&size=10&category=${type}`
    );
    const json = await res.json();
    //console.log(json.result.objects?.[0]);

    if (json.code !== "2000" || json.result.objects?.[0] == null) return;
    setData(json.result.objects[0]);
  }
  return (
    <>
      <Navbar />
      <div className="mx-auto flex w-4/5 max-w-7xl flex-col items-center gap-6">
        <h1 className=" text-2xl font-bold md:text-4xl">{title}</h1>
        <h2 className=" text-xl md:text-2xl">{subtitle}</h2>
        <div className="flex w-full flex-col gap-6">
          {data.map((datum) => (
            <ListItem
              title={datum.title}
              content={datum.content}
              imgUrl={datum.image}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PageTemplate;
