import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../component/Navbar.tsx";
import Footer from "../../component/Footer.tsx";
import { useTranslation } from "react-i18next";
import { NewsListAPIType } from "../../lib/type-info/news.ts";
import { Link } from "react-router-dom";

const NUMPAGEITEM = 4;

function News() {
  const { t } = useTranslation();
  const [newsList, setNewsList] = useState<NewsListAPIType[]>([]);
  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const currPageRef = useRef(1);

  useEffect(() => {
    load();
  }, []);

  async function load() {
    const res = await fetch(
      `https://yja.p360tec.com/api/content/getList-article-client?pages=${currPageRef.current}&size=${NUMPAGEITEM}&type=LATEST_NEWS`
    );
    const json = await res.json();
    //console.log(json);
    if (json.code === "5009") {
      setShowMoreBtn(false);
      return;
    }
    const result = json.result.objects?.[0] ?? [];
    setNewsList([...newsList, ...result]);
  }

  return (
    <>
      <Navbar />
      <div className="mx-auto w-4/5 max-w-7xl">
        <h1 className="my-10 text-3xl font-bold">{t("latestNews")}</h1>
        <div className="flex flex-col gap-3">
          {newsList.map((e) => (
            <NewsItem
              imgUrl={e.cover}
              title={e.title}
              date={e.date}
              href={`/latest_news/${e.a_id}`}
              key={e.a_id}
            />
          ))}
        </div>
        {showMoreBtn ? (
          <button
            className="btn my-10 bg-[#B2222C] text-white"
            onClick={() => {
              currPageRef.current += 1;
              load();
            }}
          >
            {t("loadMore")}
          </button>
        ) : (
          <p className="my-10 text-xl md:text-2xl">{t("no-more-data")}</p>
        )}
      </div>
      <Footer />
    </>
  );
}

function NewsItem({
  imgUrl,
  title,
  date,
  href,
}: {
  imgUrl: string;
  title: string;
  date: string;
  href: string;
}) {
  return (
    <Link
      className="grid cursor-pointer grid-cols-1 gap-3 border-b-2 border-[#B2222C] p-3 text-start md:text-xl lg:grid-cols-3"
      to={href}
    >
      <div>
        <img
          className="aspect-video w-full object-cover object-center"
          src={imgUrl}
        />
      </div>

      <div className="flex flex-col justify-between lg:col-span-2">
        <h2 className="text-[#B2222C]">{title}</h2>
        <p>{date}</p>
      </div>
    </Link>
  );
}

export default News;
