import React from "react";
import Navbar from "../component/Navbar.tsx";
import Footer from "../component/Footer.tsx";

function Test() {
  return (
    <>
      <Navbar />
      <Footer />
    </>
  );
}

export default Test;
