import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer.tsx";
import Navbar from "../../component/Navbar.tsx";
import Timeline from "./components/Timeline.tsx";
import { useTranslation } from "react-i18next";

function DevelopmentTest() {
  return (
    <>
      <Navbar />
      <main className="mx-auto flex w-11/12 max-w-7xl flex-col gap-6">
        <img
          className="h-56 object-contain object-center"
          src="/image/logo.png"
        />
        <Introduction />
        <Timeline />
      </main>
      <Footer />
    </>
  );
}

function Introduction() {
  const { t } = useTranslation();
  const [goals, setGoals] = useState("");

  useEffect(() => {
    async function load() {
      const res = await fetch(
        "https://yja.p360tec.com/api/content/get-web-content-all"
      );
      const json = await res.json();
      setGoals(json.result.goals.description);
    }
    load();
  }, []);

  return (
    <div className="px-3">
      <h2 className="m-3 text-2xl font-bold md:text-4xl">{t("Aims")}</h2>
      <p className="text-justify text-xl md:text-2xl">{goals}</p>
    </div>
  );
}

export default DevelopmentTest;
