import { useTranslation } from "react-i18next";
import PageTemplate from "../components/PageTemplates.tsx";
import React from "react";

function FundingMethods() {
  const { t, i18n } = useTranslation();

  return (
    <PageTemplate
      title={t("subsidy")}
      subtitle={t("subsidyList")}
      type="FUNDING_METHODS"
    />
  );
}

export default FundingMethods;
