import React, { useRef } from "react";

function ListItem({
  imgUrl,
  title,
  content,
}: {
  imgUrl: string;
  title: string;
  content: string;
}) {
  return (
    <article className="grid grid-cols-1 gap-10 border-b-2 border-[#B2222C] py-6 lg:grid-cols-2">
      <div className="relative pt-[56.25%]">
        <img
          className="absolute left-0 top-0 h-full w-full border object-contain object-center"
          src={imgUrl}
          alt="service image"
        />
      </div>
      <div>
        <h3 className="mb-3 text-start text-2xl text-[#B2222C] md:text-4xl">
          {title}
        </h3>
        <p
          className="text-start text-xl md:text-2xl"
          dangerouslySetInnerHTML={{ __html: content }}
        ></p>
      </div>
    </article>
  );
}

export default ListItem;
