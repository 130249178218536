import { useForm } from "react-hook-form";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import React from "react";
import axios, { AxiosResponse } from "axios";
import toast from "react-hot-toast";

const minPeopleNumber = 0;
const maxPeopleNumber = 100;

export default function Form() {
  const { t } = useTranslation();
  const [errMsg, setErrMsg] = useState<any>({});
  const [formKey, setFormKey] = useState(0);
  const [showOtherText, setShowOtherText] = useState(false);

  interface FormData {
    name?: string;
    gender?: string;
    mobile?: string;
    email?: string;
    group?: string;
    mode?: string;
    checkbox?: string;
    create_time?: string;
    disclaimer?: string;
    headcount?: number;
  }

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value;
    setShowOtherText(targetValue === "Other");
    setValue("checkbox", targetValue);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    // alert(JSON.stringify(data))
    const { disclaimer, checkbox, ...formData } = data; // Exclude 'disclaimer' from the formData
    formData.create_time = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 19);
    formData.headcount = Number(data.checkbox);
    formData.mode = formData.mode?.toString();
    console.log(formData);
    try {
      const res: AxiosResponse = await axios.post("https://yja.p360tec.com/server/formdata", formData);
      console.log(res.data);
      if (res.data.result === true) {
        toast.success(t("報名成功"));
      } else {
        toast.error(t("報名錯誤"));
      }
    } catch (e) {
      toast.error(t("報名錯誤"));
    }
  };

  return (
    <div className="flex w-full flex-col">
      <form onSubmit={handleSubmit(onSubmit)} className=" w-3/4 self-center rounded-md bg-white" key={formKey}>
        <div className="bg-white rounded-lg shadow-md p-6 border border-grey my-4">
          <div className="form-control w-full max-w-screen-md" id="form_name">
            <label className="label">
              <span className="label-text text-3xl">
                {t("姓名")} <span style={{ color: "red" }}>*</span>
              </span>
              {errors.name && <p className="text-xs text-red-500">該欄位為必填</p>}
            </label>
            <input
              type="text"
              id="name-create"
              placeholder={t("Your name")}
              className={`input input-bordered w-full max-w-screen-md ${errors.name && "input-error"
                }`}
              {...register("name", {
                required: "Name is required",
              })}
            />
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 border border-grey my-4">
          <div className="form-control w-full max-w-screen-md" id="form_gender">
            <label className="label">
              <span className="label-text  text-3xl">{t("性別") + " "}<span style={{ color: "red" }}>*</span></span>
              {errors.gender && <p className="text-xs text-red-500">{t("該欄位為必填")}</p>}
            </label>
            <div className="align_left flex flex-col">
              <label className="flex items-center text-xl">
                <input
                  type="radio"
                  value="男"
                  {...register("gender", {
                    required: "gender is required",
                  })}
                  className="my-2 mr-2"
                />
                {t("男")}
              </label>
              <label className="flex items-center text-xl">
                <input
                  type="radio"
                  value="女"
                  {...register("gender", {
                    required: "gender is required",
                  })}
                  className="my-2 mr-2"
                />
                {t("女")}
              </label>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 border border-grey my-4">
          <div className="form-control w-full max-w-screen-md" id="form_mobile">
            <label className="label">
              <span className="label-text  text-3xl">{t("聯絡電話") + " "}<span style={{ color: "red" }}>*</span></span>
              {errors.mobile && <p className="text-xs text-red-500">{t("該欄位為必填")}</p>}
            </label>
            <input
              type="text"
              id="mobile-create"
              placeholder={t("Your mobile number")}
              className={`input input-bordered w-full max-w-screen-md ${errors.mobile && "input-error"}`}
              {...register("mobile", {
                required: "mobileNo is required",
              })}
            />
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 border border-grey my-4">
          <div className="form-control w-full max-w-screen-md" id="form_email">
            <label className="label">
              <span className="label-text  text-3xl">{t("電子郵件") + " "}<span style={{ color: "red" }}>*</span></span>
              {errors.email && <p className="text-xs text-red-500">{errors.email.message === "Invalid email address" ? t("Please enter a valid email address") : t("該欄位為必填")}</p>}
            </label>
            <input
              type="email"
              id="email-create"
              placeholder={t("Your email address")}
              className={`input input-bordered w-full max-w-screen-md ${errors.email && "input-error"}`}
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Invalid email address",
                },
              })}
            />
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 border border-grey my-4">
          <div className="form-control w-full max-w-screen-md" id="form_group">
            <label className="label">
              <span className="label-text text-3xl">{t("組別") + ' '}<span style={{ color: "red" }}>*</span></span>
              {errors.group && <p className="text-xs text-red-500">{t("該欄位為必填")}</p>}
            </label>
            <div className="align_left flex flex-col">
              <label className="flex items-center text-xl">
                <input
                  type="radio"
                  value="個人"
                  {...register("group", {
                    required: "group is required",
                  })}
                  className="my-2 mr-2"
                />
                {t("個人")}
              </label>
              <label className="flex items-center text-xl">
                <input
                  type="radio"
                  value="雙人"
                  {...register("group", {
                    required: "group is required",
                  })}
                  className="my-2 mr-2"
                />
                {t("雙人")}
              </label>
              <label className="flex items-center text-xl">
                <input
                  type="radio"
                  value="家庭"
                  {...register("group", {
                    required: "group is required",
                  })}
                  className="my-2 mr-2"
                />
                {t("家庭")}
              </label>
              <label className="flex items-center text-xl">
                <input
                  type="radio"
                  value="學校"
                  {...register("group", {
                    required: "group is required",
                  })}
                  className="my-2 mr-2"
                />
                {t("學校")}
              </label>
              <label className="flex items-center text-xl">
                <input
                  type="radio"
                  value="NGO"
                  {...register("group", {
                    required: "group is required",
                  })}
                  className="my-2 mr-2"
                />
                {t("NGO")}
              </label>
              <label className="flex items-center text-xl">
                <input
                  type="radio"
                  value="團體/企業"
                  {...register("group", {
                    required: "group is required",
                  })}
                  className="my-2 mr-2"
                />
                {t("團體/企業")}
              </label>
              <label className="flex items-center text-xl">
                <input
                  type="radio"
                  value="深圳內地青年團體及居民"
                  {...register("group", {
                    required: "group is required",
                  })}
                  className="my-2 mr-2"
                />
                {t("深圳內地青年團體及居民")}
              </label>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 border border-grey my-4">
          <div className="form-control w-full max-w-screen-md" id="form_attendance">
            <label className="label">
              <span className="label-text text-3xl">{t("想要參加的比賽模式") + " "}<span style={{ color: "red" }}>*</span></span>
              {errors.mode && <p className="text-xs text-red-500">{t("該欄位為必填")}</p>}
            </label>
            <div className="align_left flex flex-col text-xl">
              <label className="text-1xl flex items-center">
                <input
                  type="checkbox"
                  value="1公里親子賽/慈善跑/Halloween嘩鬼狂歡跑"
                  {...register("mode", {
                    required: "Mode is required",
                  })}
                  className="mr-2"
                />
                {t("1公里親子賽/慈善跑/Halloween嘩鬼狂歡跑")}
              </label>
              <label className="flex items-center  ">
                <input
                  type="checkbox"
                  value="3公里個人賽"
                  className="mr-2"
                  {...register("mode", {
                    required: "Mode is required",
                  })}
                />
                {t("3公里個人賽")}
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  value="3公里校際賽"
                  className="mr-2"
                  {...register("mode", {
                    required: "Mode is required",
                  })}
                />
                {t("3公里校際賽")}
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  value="3公里隊際/慈善跑/企業賽"
                  className="mr-2"
                  {...register("mode", {
                    required: "Mode is required",
                  })}
                />
                {t("3公里隊際/慈善跑/企業賽")}
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  value="全港區際賽"
                  className="mr-2"
                  {...register("mode", {
                    required: "Mode is required",
                  })}
                />
                {t("全港區際賽")}
              </label>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 border border-grey my-4">
          <div className="form-control w-full max-w-screen-md" id="form_number_of_people">
            <label className="label">
              <span className="label-text text-3xl">{t("一同參加活動的人數") + " "}<span style={{ color: "red" }}>*</span></span>
              {errors.checkbox && <p className="text-xs text-red-500">{errors.checkbox.message === "Please enter a valid integer" ? t("Please enter a valid integer") : t("該欄位為必填")}</p>}
            </label>
            <div className="align_left flex flex-col text-xl">
              <label className="flex items-center">
                <input
                  type="radio"
                  value="1"
                  {...register("checkbox", {
                    required: "checkbox is required",
                  })}
                  className="my-2 mr-2"
                  onChange={handleRadioChange}
                />
                1
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  value="2"
                  {...register("checkbox", {
                    required: "checkbox is required",
                  })}
                  className="my-2 mr-2"
                  onChange={handleRadioChange}
                />
                2
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  value="3"
                  {...register("checkbox", {
                    required: "checkbox is required",
                  })}
                  className="my-2 mr-2"
                  onChange={handleRadioChange}
                />
                3
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  value="Other"
                  {...register("checkbox", {
                    required: "checkbox is required",
                  })}
                  className="my-2 mr-2"
                  onChange={handleRadioChange}
                />
                {t("其他")}
                {showOtherText && (
                  <input
                    type="number"
                    id="other-number-of-people-create"
                    className="ml-2 border-b border-gray-500 focus:border-indigo-500 focus:outline-none"
                    {...register("checkbox", {
                      required: "checkbox is required",
                      validate: {
                        isInteger: (value) => Number.isInteger(Number(value)) || "Please enter a valid integer",
                        min: (value) => Number(value) >= minPeopleNumber || "Please enter a valid integer",
                        max: (value) => Number(value) <= maxPeopleNumber || "Please enter a valid integer",
                      },
                    })}
                  />
                )}
              </label>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 border border-grey my-4">
          <div className="form-control w-full max-w-screen-md" id="form_disclaimer">
            <label className="label">
              <span className="label-text text-3xl">{t("活動聲明") + " "}<span style={{ color: "red" }}>*</span></span>
              {errors.disclaimer && <p className="text-xs text-red-500">{t("該欄位為必填")}</p>}
            </label>
            <label className="label">
              <span className="label-text w-full text-left text-xl">
                1. {t("在報名及活動過程中，將收集參加者的個人信息，包括但不限於姓名、聯絡方式、年齡、性別等。這些信息將僅用於活動的登記、聯絡及賽事成績的統計和公佈。")}
                <br />
                2. {t("參加者須自行評估自身的身體狀況是否適合參加此活動，活動不承擔任何因參加活動而引起的人身安全或健康隱患。")}
                <br />
                3. {t("本須知及各項規定之解釋與適用，若有任何爭議，主辦單位、承辦單位及協辦單位保有最終解釋權。")}
              </span>
            </label>
            <div className="align_left flex flex-col">
              <label className="flex items-center text-xl">
                <input
                  type="radio"
                  value="agree"
                  {...register("disclaimer", {
                    required: "disclaimer is required",
                  })}
                  className="my-2 mr-2"
                />
                {t("我明白")}
              </label>
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-success my-3">
          {t("提交")}
        </button>
      </form>
    </div>
  );
}
