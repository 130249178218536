import React, { useEffect } from "react";
import clsx from "clsx";

function BulletController({
  currItem,
  onItemSelect,
  numItem,
  timeInterval,
}: {
  currItem: number;
  onItemSelect: (idx: number) => void;
  numItem: number;
  timeInterval?: number;
}) {
  let itemList: JSX.Element[] = [];
  for (let i = 0; i < numItem; ++i) {
    itemList.push(
      <ControllerItem
        isActive={i === currItem}
        onClick={() => onItemSelect(i)}
        key={i}
      />
    );
  }

  useEffect(() => {
    if(timeInterval == null || numItem === 0) return;
    
    let id = setTimeout(() => {
      onItemSelect((currItem + 1) % numItem);
    }, timeInterval);

    return () => clearTimeout(id);
  }, [currItem, numItem]);

  return <div className="flex justify-center gap-3">{itemList}</div>;
}

function ControllerItem({ isActive, onClick }) {
  return (
    <button
      className={clsx(
        "h-3 w-3 rounded-full transition-colors",
        isActive ? "bg-[#B2222C]" : "bg-[#D9D9D9]"
      )}
      onClick={onClick}
    ></button>
  );
}

export default BulletController;
