"use client";

import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HistoryAPIType } from "../../../lib/type-info/history";

function Timeline() {
  const timeLineRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [data, setData] = useState<HistoryAPIType[]>([]);
  const [currYearIdx, setCurrYearIdx] = useState(-1);
  const [yearArr, setYearArr] = useState<number[]>([]);
  const currYear = yearArr[currYearIdx];
  const displayItem = data
    .filter((d) => new Date(d.date).getFullYear() === currYear)
    .reverse();

  const timeLineColour = [
    "#E40078",
    "#F00",
    "#FF5349",
    "#FFA500",
    "#F5BD1F",
    "#FF0",
    "#9ACD32",
  ];

  const timeLineData = [
    {
      content: "content",
    },
    {
      content: "content",
    },
    {
      content: "content",
    },
    {
      content: "content",
    },
    {
      content:
        "content content content content content contentcontent content ",
    },
  ];

  function handleMoveTimeLine(e: React.PointerEvent<HTMLDivElement>) {
    const timeLineElem = timeLineRef.current;
    if (timeLineElem == null) return;

    let initPos = e.clientX;
    let initScroll = timeLineElem.scrollLeft;

    timeLineElem.setPointerCapture(e.pointerId);

    timeLineElem.onpointermove = (e) => {
      let newScroll = initScroll - (e.clientX - initPos);
      timeLineElem.scrollTo({ left: newScroll, behavior: "instant" });
    };

    timeLineElem.onpointerup = (e) => {
      timeLineElem.onpointermove = null;
      timeLineElem.onpointerup = null;
    };
  }

  useEffect(() => {
    async function load() {
      const res = await fetch(
        "https://yja.p360tec.com/api/content/getList-development-history"
      );
      const json = await res.json();
      const result = json.result.objects[0] as HistoryAPIType[];

      const yearSet = new Set<number>();
      result.forEach((d) => {
        yearSet.add(new Date(d.date).getFullYear());
      });
      let newYearArr = [...yearSet].sort((a, b) => b - a);
      setYearArr(newYearArr);
      setCurrYearIdx(0);
      setData(result);
    }
    load();
  }, []);

  return (
    <div className="flex flex-col gap-10 px-6">
      <h2 className="my-3 text-2xl font-bold md:text-4xl">{t("ourJourney")}</h2>
      <div
        className="no-scrollbar select-none md:flex md:overflow-x-auto"
        ref={timeLineRef}
        onPointerDown={handleMoveTimeLine}
        onDragStart={() => false}
      >
        {displayItem.map((e, idx) => (
          <TimelineItem
            colour={timeLineColour[idx % timeLineColour.length]}
            title={e.title}
            date={e.date}
            content={e.content}
          />
        ))}
      </div>
      <div className="flex flex-col items-center justify-evenly gap-6 md:flex-row">
        {yearArr[currYearIdx + 1] && (
          <button
            className="btn bg-[#B2222C] text-white"
            onClick={() => setCurrYearIdx(currYearIdx + 1)}
          >
            {`${yearArr[currYearIdx + 1]}年活動回顧`}
          </button>
        )}
        {yearArr[currYearIdx - 1] && (
          <button
            className="btn bg-[#B2222C] text-white"
            onClick={() => setCurrYearIdx(currYearIdx - 1)}
          >
            {`${yearArr[currYearIdx - 1]}年活動回顧`}
          </button>
        )}
      </div>
    </div>
  );
}

function TimelineItem({
  colour,
  date,
  title,
  content,
}: {
  colour?: string;
  date: string;
  title: string;
  content: string;
}) {
  return (
    <div className="flex h-fit items-center gap-3 md:shrink-0 md:grow md:basis-[30%] md:flex-col xl:basis-[16%]">
      <div
        className="min-h-[14rem] w-2 shrink-0 md:order-2 md:h-2 md:min-h-0 md:w-full"
        style={{ backgroundColor: colour }}
      ></div>
      <p className={clsx("text-xl font-bold md:order-1 md:text-2xl")}>{date}</p>
      <div
        className={clsx(
          "flex flex-auto flex-col items-start gap-3 p-6 md:order-3"
        )}
      >
        <h3 className="text-xl font-bold md:text-2xl">{title}</h3>
        <p className="text-justify text-lg md:text-xl">{content}</p>
      </div>
    </div>
  );
}

export default Timeline;
