import React, { useEffect, useState } from "react";
import { FaCalendar } from "react-icons/fa";
import BulletController from "./BulletController.tsx";
import clsx from "clsx";
import FadeAnimator from "../../../component/Animator/FadeAnimator.tsx";
import { Link } from "react-router-dom";

type NewsDataType = {
  date: string;
  isNew: boolean;
  title: string;
};

const newsListDemo = [
  {
    date: "2023年8月1日",
    isNew: true,
    title: "有關「XXX」的網上簡介會已於2023年10日31日舉行",
  },
  {
    date: "2022年8月1日",
    isNew: false,
    title: "有關「XXX2」的網上簡介會已於2023年10日31日舉行",
  },
  {
    date: "2021年8月1日",
    isNew: true,
    title: "有關「XXX3」的網上簡介會已於2023年10日31日舉行",
  },
];

function LatestNews() {
  const [newsList, setNewsList] = useState([]);

  const [currNewsIdx, setCurrNewsIdx] = useState(0);
  const currNews = newsList[currNewsIdx];

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    const res = await fetch(
      "https://yja.p360tec.com/api/content/getList-article-client?pages=1&size=10&type=LATEST_NEWS"
    );
    const json = await res.json();
    const result = json.result.objects?.[0] ?? [];

    setNewsList(
      result.map((d) => ({
        date: d.date,
        isNew: true,
        title: d.title,
      }))
    );
  }

  function handleDisplayNextNews(nextNewsIdx: number) {
    setCurrNewsIdx(nextNewsIdx);
  }

  return (
    <article className="flex w-11/12 flex-col items-center gap-10 border-t border-gray-400 pt-10">
      <h2 className="text-2xl font-bold lg:text-4xl">最新消息</h2>
        <NewsDetails newsData={currNews} />
      <BulletController
        currItem={currNewsIdx}
        onItemSelect={handleDisplayNextNews}
        numItem={newsList.length}
        timeInterval={5000}
      />
      <Link className="btn bg-[#B2222C] text-white" to="/latest_news">
        更多消息
      </Link>
    </article>
  );
}

function NewsDetails({ newsData }: { newsData?: NewsDataType }) {
  return (
    <section
      className={
        "flex flex-col gap-6 transition-opacity duration-500 md:text-2xl"
      }
    >
      <div className="flex items-center justify-center gap-3">
        <FaCalendar className=" h-12 w-12 md:h-16 md:w-16" />
        <p>{newsData?.date}</p>
        {newsData?.isNew && (
          <div className="rounded-lg bg-[#FF0100] px-6 py-1 text-white">
            New
          </div>
        )}
      </div>
      <p>{newsData?.title}</p>
    </section>
  );
}

export default LatestNews;
