import React, { useEffect, useState } from "react";
import { t } from "i18next";
import Navbar from "../component/Navbar.tsx";
import Footer from "../component/Footer.tsx";
import { FaPhone, FaLocationDot, FaClock, FaFax, FaEnvelope } from "react-icons/fa6";

type APIType = {
  phone: string;
  address: string;
  businessHour: string;
  fax: string;
  email: string;
};

function ContactUs() {
  const [data, setData] = useState<APIType>();

  useEffect(() => {
    async function load() {
      const res = await fetch("https://yja.p360tec.com/api/content/get-web-content-all");
      const json = await res.json();

      if (json.code !== "2000") throw new Error("API Error");
      setData(json.result.contactUs);
    }

    load();
  }, []);

  return (
    <>
      <Navbar />
      <main className="mx-auto w-11/12 max-w-7xl">
        <h1 className="mb-14 text-2xl font-bold md:text-4xl">{t("contactus")}</h1>
        <article>
          <address className="text-xl font-light md:text-2xl">
            {data && (
              <ul className="flex flex-col gap-6">
                <li className="flex items-center justify-center gap-3">
                  <FaPhone />
                  {data.phone}
                </li>
                <li className="flex items-center justify-center gap-3">
                  <FaLocationDot />
                  {data.address}
                </li>
                <li className="flex items-center justify-center gap-3">
                  <FaClock />
                  {data.businessHour}
                </li>
                <li className="flex items-center justify-center gap-3">
                  <FaFax />
                  {data.fax}
                </li>
                <li className="flex items-center justify-center gap-3">
                  <FaEnvelope />
                  <a href={`mailto:${data.email}`}> {data.email}</a>
                </li>
              </ul>
            )}
          </address>
        </article>
      </main>
      <Footer />
    </>
  );
}

export default ContactUs;
