import React from "react";
import { Link } from "react-router-dom";

type ActivityLinkType = {
  href: string;
  title: string;
  imgUrl: string;
  date: string;
};

export default function ActivityLink({
  href,
  imgUrl,
  title,
  date,
}: ActivityLinkType) {
  return (
    <Link
      className="grid grid-cols-1 gap-6 border-b-2 border-[#B2222C] p-3 lg:grid-cols-3 lg:gap-10"
      to={href}
    >
      <div className="col-span-1 aspect-video">
        <img
          className="h-full w-full border object-contain object-center"
          src={imgUrl}
        />
      </div>
      <div className="col-span-1 flex flex-col items-start justify-center gap-3 text-start lg:col-span-2 lg:justify-between">
        <p className="text-xl text-[#B2222C]">{title}</p>
        <p>日期：{date}</p>
      </div>
    </Link>
  );
}

export { type ActivityLinkType };
