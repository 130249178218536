import React, { useEffect, useState } from "react";
import BulletController from "./BulletController.tsx";
import clsx from "clsx";
import FadeAnimator from "../../../component/Animator/FadeAnimator.tsx";
import { BannerDataType } from "../../../lib/type-info/banner.ts";
import { Link } from "react-router-dom";

type GalleryItemType = {
  title: string;
  content: string;
  href?: string;
  imgUrl: string;
};

function Gallery() {
  const [itemList, setItemList] = useState<GalleryItemType[]>();
  const [currItemIdx, setCurrItemIdx] = useState(0);

  function handleDisplayNextItem(nextItemIdx: number) {
    setCurrItemIdx(nextItemIdx);
  }

  useEffect(() => {
    async function load() {
      const res = await fetch(
        "https://yja.p360tec.com/api/content/getList-banner-client?pages=1&size=10"
      );
      const json = await res.json();
      const result = json.result.objects?.[0]?? [] as BannerDataType[];

      const itemList: GalleryItemType[] = result.map((datum) => ({
        title: datum.title,
        content: datum.content,
        imgUrl: datum.image,
      }));

      setItemList(itemList);
    }

    load();
  }, []);

  const currItem = itemList?.[currItemIdx];

  return (
    <article className="w-full">
      <GalleryFrame item={currItem} />
      <BulletController
        currItem={currItemIdx}
        onItemSelect={handleDisplayNextItem}
        numItem={itemList?.length ?? 0}
        timeInterval={5000}
      />
    </article>
  );
}

function GalleryFrame({ item }: { item?: GalleryItemType }) {
  return (
    <FadeAnimator>
      <div
        className={
          "relative mb-6 w-full border text-start text-white transition-opacity duration-500"
        }
      >
        <img
          className=" aspect-video w-full object-contain object-center"
          src={item?.imgUrl}
        />
        <div className="absolute bottom-1 left-1 flex max-h-[90%] w-11/12 shrink-0 flex-col items-start gap-3 bg-gray-600/50 p-6 sm:bottom-6 sm:left-6 sm:w-3/5">
          <h2 className="font-bold text-sm sm:text-2xl lg:text-4xl">{item?.title}</h2>
          <p className="basis-fit line-clamp-2 w-full sm:line-clamp-none text-xs sm:text-xl lg:text-2xl">
            {item?.content}
          </p>
          {item?.href && (
            <Link
              className="btn btn-sm order-3 shrink-0 bg-[#B2222C] text-xs  text-white md:btn-md sm:text-base"
              to={item.href}
            >
              了解更多
            </Link>
          )}
        </div>
      </div>
    </FadeAnimator>
  );
}

export default Gallery;
