import { useTranslation } from "react-i18next";
import PageTemplate from "../components/PageTemplates.tsx";
import React from "react";

function Partnership() {
  const { t, i18n } = useTranslation();

  return (
    <PageTemplate
      title={t("partnershipDevelopment")}
      subtitle={t("relationshipList")}
      type="PARTNERSHIP_ESTABLISHMENT"
    />
  );
}

export default Partnership;
