import React, { useEffect, useState } from "react";
import Navbar from "../../component/Navbar.tsx";
import Footer from "../../component/Footer.tsx";
import { FaAngleLeft, FaAngleRight, FaArrowLeft } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import Carousel, { MediaType } from "../../component/Carousel.tsx";
import { GalleryDetailDataType } from "../../lib/type-info/activity-gallery.ts";

function ActivityGalleryDetails() {
  let { id } = useParams();
  const [data, setData] = useState<GalleryDetailDataType | undefined>();

  const dataTest = {
    date: "2023-08-26",
    title: "築航天・科技夢 - 四川・西昌衛星發射中心航太科技主題2023夏令營",
    photoList: [
      "https://daisyui.com/images/stock/photo-1559703248-dcaaec9fab78.jpg",
      "https://daisyui.com/images/stock/photo-1565098772267-60af42b81ef2.jpg",
      "/image/logo.png",
      "/image/logo.png",
      "/image/logo.png",
      "/image/logo.png",
    ],
  };

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    const res = await fetch(
      `https://yja.p360tec.com/api/album/getDetail-album/${id}`,
      { cache: "no-cache" }
    );

    const json = await res.json();
    //console.log(json);
    setData(json.result);
  }

  return (
    <>
      <Navbar />
      {data && (
        <div className="mx-auto flex w-4/5 max-w-7xl flex-col gap-6">
          <p className="text-start text-2xl text-[#E0115F]">{data.date}</p>
          <h1 className=" text-2xl font-bold md:text-4xl">{data.title}</h1>
          <Gallery
            mediaList={data.albums.map((p) => ({ url: p, type: "image" }))}
          />
          <Link
            className="btn btn-outline btn-error self-start"
            to="/activity_gallery"
          >
            <FaArrowLeft className=" mr-3" />
            返回
          </Link>
        </div>
      )}
      <Footer />
    </>
  );
}

function Gallery({ mediaList }: { mediaList: MediaType[] }) {
  const [currMediaIdx, setCurrMediaIdx] = useState(0);
  const currMedia = mediaList[currMediaIdx];

  const [scrollTo, setScrollTo] = useState<"initial" | "forward" | "backward">(
    "initial"
  );
  const numPageItem = 3;

  function handleMoveForward() {
    setScrollTo("forward");
  }

  function handleMoveBackward() {
    setScrollTo("backward");
  }

  function handlePhotoSelect(photoIdx: number) {
    setCurrMediaIdx(photoIdx);
  }

  return (
    <div className="flex flex-col gap-3 border-b-2 border-[#B2222C] py-3">
      <img
        className="aspect-video border border-gray-400 object-contain object-center"
        src={currMedia?.url}
      />
      <div className="flex items-center gap-3 border border-gray-400 ">
        <FaAngleLeft
          className="h-10 w-10 shrink-0 cursor-pointer"
          onClick={handleMoveForward}
        />
        <Carousel
          mediaList={mediaList}
          numPageItem={numPageItem}
          scrollTo={scrollTo}
          onMediaSelect={handlePhotoSelect}
          className="aspect-[48/9] grow"
        />
        <FaAngleRight
          className="h-10 w-10 shrink-0 cursor-pointer"
          onClick={handleMoveBackward}
        />
      </div>
    </div>
  );
}

export default ActivityGalleryDetails;
