import { BrowserRouter, Route, Routes, ScrollRestoration } from "react-router-dom";
import "./App.css";
import { Login } from "./page/Login.tsx";
import i18n from "./i18n.tsx";
import { Toaster } from "react-hot-toast";
import MainTest from "./page/Main/MainTest.tsx";
import ActivityCategoryTest from "./page/ActivityCategory/ActivityCategory.tsx";
import OurPartner from "./page/OurPartner/OurPartner.tsx";
import DevelopmentTest from "./page/Development/Development.tsx";
import News from "./page/News/News.tsx";
import ActivityGallery from "./page/ActivityGallery/ActivityGallery.tsx";
import ActivityGalleryDetails from "./page/ActivityGallery/ActivityGalleryDetails.tsx";
import LatestActivity from "./page/ActivityCatalogue/LatestActivity/LatestActivity.tsx";
import Activity from "./page/Activity/ActivityTest.tsx";
import PastActivity from "./page/ActivityCatalogue/PastActivity/PastActivity.tsx";
import CareerTraining from "./page/CoreServices/CareerTraining/CareerTraining.tsx";
import FutureActivity from "./page/ActivityCatalogue/FutureActivity/FutureActivity.tsx";
import FundingMethods from "./page/CoreServices/FundingMethods/FundingMethods.tsx";
import CooperationSuggestion from "./page/CoreServices/CooperationSuggestion/CooperationSuggestion.tsx";
import Partnership from "./page/CoreServices/Partnership/Partnership.tsx";
import Entrepreneurship from "./page/CoreServices/Entrepreneurship/Entrepreneurship.tsx";
import ContactUs from "./page/ContactUs.tsx";
import Test from "./page/Test.tsx";
import ScrollToTop from "./component/ScrollToTop.tsx";
import HighlightRun from "./page/HighlightRun.tsx";

function App() {
  return (
    <div className="App">
      <Toaster position="top-center" reverseOrder={false} toastOptions={{ duration: 2000 }} />
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/future_activity" element={<FutureActivity />}></Route>
            <Route path="/career" element={<Entrepreneurship />}></Route>
            <Route path="/aboutus" element={<DevelopmentTest />}></Route>
            <Route path="/contactus" element={<ContactUs />}></Route>
            <Route path="/latest_news" element={<News />}></Route>
            <Route path="/development" element={<DevelopmentTest />}></Route>
            <Route path="/career_training" element={<CareerTraining />}></Route>
            <Route path="/funding_methods" element={<FundingMethods />}></Route>
            <Route path="/cooperation" element={<CooperationSuggestion />}></Route>
            <Route path="/Relationship" element={<Partnership />}></Route>
            <Route path="/activity_category" element={<ActivityCategoryTest />}></Route>
            <Route path="/activity_category/:articleId" element={<Activity prevPageUrl="/activity_category" />}></Route>
            <Route path="/latest_activity" element={<LatestActivity />}></Route>
            <Route path="/past_activity" element={<PastActivity />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/" element={<MainTest />}></Route>

            <Route path="/latest_activity/:articleId" element={<Activity prevPageUrl="/latest_activity" />} />
            <Route path="/past_activity/:articleId" element={<Activity prevPageUrl="/past_activity" />} />
            <Route path="/future_activity/:articleId" element={<Activity prevPageUrl="/future_activity" />} />
            <Route path="/latest_news/:articleId" element={<Activity prevPageUrl="/latest_news" />} />
            <Route path="/test1" element={<Test />}></Route>
            <Route path="/our_partner" element={<OurPartner />}></Route>
            <Route path="/activity_gallery" element={<ActivityGallery />}></Route>
            <Route path="/activity_gallery_details/:id" element={<ActivityGalleryDetails />}></Route>
            <Route path="/highlightRun" element={<HighlightRun />}></Route>
          </Routes>
          <ScrollToTop />
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
