import React from "react";

import Navbar from "../component/Navbar.tsx";
import Footer from "../component/Footer.tsx";
import Form from "../component/form.tsx";
import { useTranslation } from "react-i18next";

function HighlightRun() {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div className="mx-auto max-w-screen-md flex-col">
        <div className="flex">
          <img src="https://yja.p360tec.com/api/banner/20240614175135658/20240614175135658.jpg" className="max-w-24 h-100 mx-auto w-3/5" />
        </div>
        <div className="px-10 text-3xl" id="title_highlightRun">
          <b>{t("「HI螢跑2024‧香港站 X IM 螢光音樂節」 活動報名")}</b>
        </div>
        <div className="grid grid-flow-row auto-rows-max">
          <div>{t("活動時間：於2024年10月26日萬聖節週末舉行")}</div>
          <div>{t("活動地點：灣仔海濱長廊")}</div>
          <div>{t("客戶服務熱線：+852 3686 0071")}</div>
          <div>
            <a href="mailto:enquiry@mcahk.com">{t("電郵查詢：enquiry@mcahk.com")} </a>
          </div>
          <div className="text-left text-xl">{t("原價：HK$448/位")}</div>
          <div className="text-left text-xl">{t("早鳥優惠： 個人：HK$388/位 雙人：HK$368/2位起 家庭3人：HK$348/3位起")}</div>
          <div className="text-left text-xl">{t("學校：HK$288/位")}</div>
          <div className="text-left text-xl">{t("NGO非牟利單位：NGO旗下會員報名後，報名費的20%作為捐款給合作NGO用途。")}</div>
          <div className="text-left text-xl">{t("團體/企業：HK$348/10位起")} </div>
          <div className="text-left text-xl">{t("深圳內地青年團體及居民：跟早鳥優惠")}</div>
        </div>

        <Form />
      </div>

      <Footer />
    </>
  );
}

export default HighlightRun;
