import React from "react";
import { Link } from "react-router-dom";

function OurService() {
  const serviceList = [
    {
      imgUrl: "image/main_image0.png",
      text: "遊學考察",
      href: "#",
    },
    {
      imgUrl: "image/main_image4.png",
      text: "設計師生涯規劃",
      href: "#",
    },
  ];

  return (
    <div className="flex w-11/12 flex-col border-t border-gray-400 pt-3">
      <h2 className=" text-2xl font-bold lg:text-4xl">我們的服務</h2>
      <div className=" grid grid-cols-1 lg:grid-cols-2">
        {serviceList.map((e, idx) => (
          <ServiceCard
            imgUrl={e.imgUrl}
            text={e.text}
            href={e.href}
            key={idx}
          />
        ))}
      </div>
    </div>
  );
}

function ServiceCard({ imgUrl, text, href }) {
  return (
    <div className="flex flex-col items-center gap-3 p-3">
      <img
        src={imgUrl}
        className="aspect-video w-full object-cover object-center"
      />
      <p>{text}</p>
      <Link to={href} className="btn bg-[#B2222C] text-white">
        了解更多
      </Link>
    </div>
  );
}

export default OurService;
