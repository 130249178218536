import React from "react";
export function Login() {
  // thx Liam
  const pressEnter = (e) => {
    if (e.key === "Enter") {
      verifyForm();
    }
  };

  return (
    <>
      <div className="flex w-full h-screen items-center justify-center">
        <div className="card max-w-xl lg:card-side bg-base-200 shadow-xl glass m-5">
          <figure>
            <img src="image/CMS_background.png" className="object-none w-96 lg:w-48 h-full pointer-events-none select-none" loading="lazy" />
          </figure>
          <div className="card-body">
            <h2 className="card-title justify-center">Login</h2>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Username</span>
              </label>
              <input type="text" placeholder="Username" id="username" className="input input-bordered w-full" onKeyDown={pressEnter} />
              <label className="label">
                <span className="label-text">Password</span>
              </label>
              <input type="password" placeholder="Password" id="password" className="input input-bordered w-full" onKeyDown={pressEnter} />
            </div>
            <div className="card-actions mt-12">
              <button className="btn btn-outline w-full" onClick={verifyForm}>
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function verifyForm() {}
