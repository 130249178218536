import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer.tsx";
import Navbar from "../../component/Navbar.tsx";
import Pagination from "../../component/Pagination.tsx";
import { GalleryDataType } from "../../lib/type-info/activity-gallery.ts";
import { Link } from "react-router-dom";

const NUMPAGEITEM = 6;

export default function ActivityGallery() {
  const [galleryList, setGalleryList] = useState<GalleryDataType[]>([]);
  const [currPage, setCurrPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [years, setYears] = useState<string[]>([]);
  const [currYear, setCurrYear] = useState("");

  useEffect(() => {
    Init();
  }, []);

  async function Init() {
    const res = await fetch(
      `https://yja.p360tec.com/api/album/getAll-album-client?size=${NUMPAGEITEM}&pages=1&year=`
    );
    const json = await res.json();
    //console.log(json);

    const newYears: string[] = json.result.years ?? [];
    setYears(newYears);

    let galleryListRaw = json.result.objects?.[0] ?? [];
    setGalleryList(galleryListRaw);

    setTotalPage(json.result.total);
  }

  async function loadData(year: string, page: number) {
    const res = await fetch(
      `https://yja.p360tec.com/api/album/getAll-album-client?size=${NUMPAGEITEM}&pages=${page}&year=${year}`
    );
    const json = await res.json();
    //console.log(json);

    let galleryListRaw = json.result.objects[0];
    setGalleryList(galleryListRaw);
    setTotalPage(json.result.total);
  }

  function handleYearChange(yr: string) {
    setCurrYear(yr);
    loadData(yr, currPage);
  }

  function handlePageChange(newPage: number) {
    setCurrPage(newPage);
    loadData(currYear, newPage);
  }

  return (
    <>
      <Navbar />
      <div className="mx-auto w-4/5 max-w-7xl">
        <div className="flex justify-center text-4xl font-bold">活動相片集</div>
        <div className="my-3 p-3 text-end">
          <YearSelection
            years={years}
            selectedYear={currYear}
            onChange={handleYearChange}
          />
        </div>
        <div className="grid grid-cols-1 gap-x-3 gap-y-10 p-6 md:grid-cols-2 lg:grid-cols-3">
          {galleryList.map((li) => (
            <GalleryItem
              imgUrl={li.cover}
              date={li.date}
              title={li.title}
              href={`/activity_gallery_details/${li.a_id}`}
              key={li.a_id}
            />
          ))}
        </div>
        <Pagination
          currPage={currPage}
          totalPage={totalPage}
          onPageChange={handlePageChange}
        />
      </div>

      <Footer />
    </>
  );
}

function GalleryItem({ imgUrl, date, title, href }) {
  return (
    <div className="flex flex-col items-center gap-3">
      <div className="relative w-full">
        <div className="relative w-full pt-[56.25%] shadow-lg">
          <img
            src={imgUrl}
            className="absolute left-0 top-0 h-full w-full object-contain object-center"
          />
        </div>
        <div
          className="absolute -bottom-2 -left-3 w-fit rounded-full 
        bg-red-700 p-2 text-white"
        >
          {date}
        </div>
      </div>
      <p>{title}</p>
      <Link className="btn bg-red-700 text-white" to={href}>
        了解更多
      </Link>
    </div>
  );
}

function YearSelection({
  years,
  selectedYear,
  onChange,
}: {
  years: string[];
  selectedYear: string;
  onChange: (year: string) => void;
}) {
  return (
    <select
      className="select select-bordered w-full max-w-xs"
      value={selectedYear}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value={""}>所有年度</option>
      {years.map((yr) => (
        <option value={yr} key={yr}>{yr}</option>
      ))}
    </select>
  );
}
