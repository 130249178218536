import React from "react";
import ActivityListTemplate from "../components/ActivityListTemplate.tsx";
import { useTranslation } from "react-i18next";

export default function PastActivity() {
  const { t } = useTranslation();

  return (
    <ActivityListTemplate type="PAST_ACTIVITY" title={t("activityReview")} />
  );
}
