import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer.tsx";
import Navbar from "../../component/Navbar.tsx";
import { useTranslation } from "react-i18next";
import { PartnerListAPIType } from "../../lib/type-info/partner.ts";

function OurPartner() {
  const { t } = useTranslation();
  const [partnerList, setPartnerList] = useState<PartnerListAPIType[]>([]);

  const partnerListDemo = [{ imgUrl: "image/partner/HKBA.png", text: "香港佛教聯合會" }];

  useEffect(() => {
    async function load() {
      const res = await fetch("https://yja.p360tec.com/api/content/getList-partner");
      const json = await res.json();
      const result = json.result.objects?.[0] ?? [];
      setPartnerList(result);
    }
    load();
  }, []);

  return (
    <>
      <Navbar />
      <main className="mx-auto w-11/12 max-w-7xl">
        <h1 className="my-10 text-3xl font-bold">{t("partnershipList")}</h1>
        <div className="my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5">
          {partnerList.map((e) => (
            <PartnerCard imgUrl={e.image} text={e.name} key={e.p_id} />
          ))}
        </div>
      </main>

      <Footer />
    </>
  );
}

function PartnerCard({ imgUrl, text }: { imgUrl: string; text: string }) {
  return (
    <div className="flex flex-col items-center gap-3 text-xl font-bold md:text-2xl">
      <img className="h-48 w-full object-contain object-center" src={imgUrl} />
      {text}
    </div>
  );
}

export default OurPartner;
