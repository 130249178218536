import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContactUsAPIType } from "../lib/type-info/contact-us";
import { Link } from "react-router-dom";
import { FaFacebook, FaSquareInstagram, FaYoutube } from "react-icons/fa6";
import clsx from "clsx";
import { IconType } from "react-icons";

export default function Footer() {
  return (
    <footer className="mx-auto mt-10 grid w-11/12 grid-cols-1 gap-6 border-t-2 border-red-700 pt-6 md:grid-cols-2 lg:grid-cols-4 lg:justify-items-center">
      <ContactUs />
      <Nav />
      <SocialMedia />
      <RegisterEmail />
    </footer>
  );
}

function ContactUs() {
  const { t } = useTranslation();
  const [data, setData] = useState<ContactUsAPIType>();

  useEffect(() => {
    async function load() {
      const res = await fetch("https://yja.p360tec.com/api/content/get-web-content-all");
      const json = await res.json();
      setData(json.result.contactUs);
    }
    load();
  }, []);

  return (
    <address className="text-start">
      <h2 className="mb-3 text-2xl font-bold">{t("contactus")}</h2>
      <ul>
        <li>
          {t("address")}: {data?.address}
        </li>
        <li>
          <a href={`mailto:${data?.email}`}>
            {t("email")}: {data?.email}
          </a>
        </li>
        <li>
          {t("phone")}: {data?.phone}
        </li>
        <li>
          {t("fax")}: {data?.fax}
        </li>
      </ul>
    </address>
  );
}

function Nav() {
  const { t } = useTranslation();

  return (
    <nav className="text-start">
      <h2 className="mb-3 text-2xl font-bold">{t("sitemap")}</h2>
      <ul>
        <li>
          <Link to={"/aboutus"} className="link-hover link">
            {t("aboutus")}
          </Link>
        </li>
        <li>
          <Link className="link-hover link" to="/career_training">
            {t("services")}
          </Link>
        </li>
        <li>
          <Link className="link-hover link" to={"/our_partner"}>
            {t("partnership")}
          </Link>
        </li>
        <li>
          <Link className="link-hover link" to="/contactus">
            {t("contactus")}
          </Link>
        </li>
      </ul>
    </nav>
  );
}

function SocialMedia() {
  const { t } = useTranslation();
  return (
    <article className="text-start">
      <h2 className="mb-3 text-2xl font-bold">{t("socialMedia")}</h2>
      <ul className="flex flex-col items-start gap-3 md:flex-row">
        <SocialMediaLink href="#" Icon={FaFacebook} />
        <SocialMediaLink href="#" Icon={FaSquareInstagram} />
        <SocialMediaLink href="#" Icon={FaYoutube} />
      </ul>
    </article>
  );
}

function SocialMediaLink({ href, Icon }: { href: string; Icon: IconType }) {
  return (
    <li className="rounded-xl border-2 border-gray-600 p-1 ">
      <Link to={href}>
        <Icon className="h-12 w-12" />
      </Link>
    </li>
  );
}

function RegisterEmail() {
  const { t } = useTranslation();
  const [submissionState, setSubmissionState] = useState<"pending" | "sending" | "success" | "fail">("pending");

  useEffect(() => {
    let timeoutId;
    if (submissionState === "success" || submissionState === "fail") timeoutId = setTimeout(() => setSubmissionState("pending"), 1500);

    return () => clearTimeout(timeoutId);
  }, [submissionState]);

  async function handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let formdata = new FormData(e.currentTarget);

    const body = {
      name: formdata.get("name"),
      email: formdata.get("email"),
    };

    setSubmissionState("sending");

    try {
      const res = await fetch("https://yja.p360tec.com/api/subscription/insert", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      const json = await res.json();

      json.code === "2000" ? setSubmissionState("success") : setSubmissionState("fail");
    } catch (e) {
      setSubmissionState("fail");
    }
  }

  return (
    <article className="text-start">
      <h2 className="mb-3 text-2xl font-bold">{t("follow")}</h2>
      <form className="flex flex-col gap-3" onSubmit={handleFormSubmit}>
        <input type="text" placeholder={t("name")} name="name" className="input input-bordered w-full max-w-xs" required />
        <input type="email" name="email" placeholder={t("emailAddress")} className="input input-bordered w-full max-w-xs" required />
        <button className="btn max-w-xs bg-red-700 text-white" disabled={submissionState === "sending"}>
          {submissionState === "sending" ? <span className="loading loading-spinner loading-md"></span> : t("subscribe")}
        </button>
        <div className="relative">
          <AlertMsg show={submissionState === "success"} text={t("successfully_submit")} />
          <AlertMsg show={submissionState === "fail"} text={t("fail_to_submit")} />
        </div>
      </form>
    </article>
  );
}

function AlertMsg({ show, text }) {
  return <p className={clsx("absolute text-xl font-bold text-red-700 transition-opacity duration-300", show ? "opacity-100" : "opacity-0")}>{text}</p>;
}
