import React, { useEffect, useRef } from "react";
import Footer from "../../component/Footer.tsx";
import Navbar from "../../component/Navbar.tsx";
import { useState } from "react";
import { useParams } from "react-router";
import Carousel, { MediaType } from "../../component/Carousel.tsx";
import { FaAngleLeft, FaAngleRight, FaArrowLeft } from "react-icons/fa6";
import clsx from "clsx";
import { Link } from "react-router-dom";

type ActivityDataType = {
  title: string;
  content: string;
  mediaList: MediaType[];
  date: string;
};

function Activity({ prevPageUrl }: { prevPageUrl: string }) {
  const { articleId } = useParams();
  const [pageData, setPageData] = useState<ActivityDataType | null>(null);

  useEffect(() => {
    async function loadArticle() {
      const res = await fetch(
        `https://yja.p360tec.com/api/content/getDetail-article/${articleId}`
      );

      const result = await res.json();

      //console.log(result);

      if (result.code !== "2000") return;

      const data = result.result;
      let photoList: MediaType[] = data.articleImages.map((e: string) => ({
        url: e,
        type: "image",
      }));
      let videoList: MediaType[] = data.videos.map((e: string) => ({
        url: e,
        type: "video",
      }));
      let mediaList = photoList.concat(videoList);
      setPageData({
        title: data.title,
        content: data.content,
        mediaList: mediaList,
        date: data.modifyTime,
      });
    }
    loadArticle();
  }, []);

  if (pageData)
    return (
      <>
        <Navbar />
        <main className="mx-auto flex w-4/5 flex-col gap-3">
          <section
            className={clsx(
              "grid grid-cols-1 gap-3",
              pageData.mediaList.length > 0 && "lg:grid-cols-2"
            )}
          >
            <Article
              title={pageData.title}
              content={pageData.content}
              date={pageData.date}
            />
            {pageData.mediaList.length > 0 && (
              <PhotoGallery mediaList={pageData.mediaList} />
            )}
          </section>
          <hr className=" border border-[#B2222C]" />

          <Link
            to={prevPageUrl}
            className="btn btn-outline btn-error self-start"
          >
            <FaArrowLeft className="mr-3" />
            返回
          </Link>
        </main>
        <Footer />
      </>
    );
  else return <></>;
}

function Article({ title, content, date }) {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) contentRef.current.innerHTML = content;
  }, [content]);

  return (
    <article className="flex flex-col gap-3">
      <h1 className="my-3 text-start text-2xl text-[#B2222C]">{title}</h1>
      <div className="text-justify text-xl" ref={contentRef}></div>
      <p className="mt-auto text-start">日期: {date}</p>
    </article>
  );
}

function PhotoGallery({ mediaList }: { mediaList: MediaType[] }) {
  const [currMediaIdx, setCurrMediaIdx] = useState(0);
  const currMedia = mediaList[currMediaIdx];
  const mediaElement =
    currMedia?.type === "video" ? (
      <video
        width={504}
        height={378}
        controls
        autoPlay
        muted
        className={clsx("aspect-video w-full object-cover object-center")}
      >
        <source
          src={currMedia.url}
          type={`video/${currMedia.url.split(".").pop()}`}
        />
      </video>
    ) : (
      <img
        src={currMedia.url}
        width={504}
        height={378}
        className="aspect-video w-full border object-contain object-center"
      />
    );
  const [scrollTo, setScrollTo] = useState<"initial" | "forward" | "backward">(
    "initial"
  );
  return (
    <div className="flex flex-col items-center gap-3">
      {mediaElement}
      <div className="flex w-full items-center border">
        <FaAngleLeft
          className="h-6 w-6 shrink-0 cursor-pointer"
          onClick={() => setScrollTo("forward")}
        />
        <Carousel
          mediaList={mediaList}
          numPageItem={3}
          scrollTo={scrollTo}
          onMediaSelect={setCurrMediaIdx}
          className="aspect-[48/9] h-full grow"
        />
        <FaAngleRight
          className="h-6 w-6 shrink-0 cursor-pointer"
          onClick={() => setScrollTo("backward")}
        />
      </div>
    </div>
  );
}

function Photo({ imgUrl, onClick }) {
  return (
    <div
      className="h-[20vw] w-1/3 shrink-0 cursor-pointer p-1 lg:h-[10vw] lg:max-h-48"
      onClick={onClick}
    >
      <img
        src={imgUrl}
        width={87}
        height={58}
        className="h-full w-full rounded-lg border-2 border-gray-400 object-contain object-center p-1"
      />
    </div>
  );
}

export default Activity;
