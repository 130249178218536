import React, { useEffect, useRef, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

function OurPartner() {
  return (
    <div className="flex w-11/12 flex-col items-center gap-6 border-t border-gray-400 pt-10">
      <h2 className="text-2xl font-bold lg:text-4xl">我們的合作夥伴</h2>
      <PartnerScroll />
      <Link to="/our_partner" className="btn bg-[#B2222C] text-white">
        查看更多
      </Link>
    </div>
  );
}

function PartnerScroll() {
  let [leftPosition, setLeftPosition] = useState(0);
  let partnerCardRef = useRef<HTMLAnchorElement>(null);
  const [partnerList, setPartnerList] = useState<
    { imgSrc: string; partnerName: string }[]
  >([]);

  let partnerListDemo = [
    {
      imgSrc: "image/partner/HKBA.png",
      partnerName: "香港佛教聯合會",
    },
    {
      imgSrc: "image/partner/kids.png",
      partnerName: "小君子",
    },
    {
      imgSrc: "image/partner/math.png",
      partnerName: "數博",
    },
    {
      imgSrc: "image/partner/ABLE.png",
      partnerName: "艾藍兒童發展中心",
    },
    {
      imgSrc: "image/partner/HM.png",
      partnerName: "思健兒童發展中心",
    },
  ];

  const [numPageItem, setNumPageItem] = useState(0);
  const numPage = Math.ceil(partnerList.length / numPageItem);
  const containerWidthPercent = numPage * 100;
  const cardWidthPercent = (1 / (numPageItem * numPage)) * 100;

  useEffect(() => {
    function handleWindowResize() {
      let newNumPageItem;
      const windowWidth = document.documentElement.clientWidth;
      newNumPageItem = windowWidth < 768 ? 1 : 3;
      setNumPageItem(newNumPageItem);
      setLeftPosition(0);
    }

    handleWindowResize();

    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    async function load() {
      const res = await fetch(
        "https://yja.p360tec.com/api/content/getList-partner"
      );
      const json = await res.json();
      const result = json.result.objects?.[0] ?? [];

      setPartnerList(
        result.map((d) => ({
          imgSrc: d.image,
          partnerName: d.name,
        }))
      );
    }

    load();
  }, []);

  function handleMoveBackward() {
    if (partnerCardRef.current == null || partnerList.length <= numPageItem) return;
    const cardWidth = partnerCardRef.current.offsetWidth;
    let newLeftPosition = leftPosition + numPageItem * cardWidth;
    newLeftPosition = newLeftPosition > 0 ? 0 : newLeftPosition;

    setLeftPosition(newLeftPosition);
  }

  function handleMoveForward() {
    if (partnerCardRef.current == null || partnerList.length <= numPageItem) return;
    const cardWidth = partnerCardRef.current.offsetWidth;
    if (leftPosition == -(partnerList.length - numPageItem) * cardWidth) return;
    let newLeftPosition = leftPosition - numPageItem * cardWidth;
    let endPosition = -(partnerList.length - numPageItem) * cardWidth;
    newLeftPosition =
      newLeftPosition < endPosition ? endPosition : newLeftPosition;
    setLeftPosition(newLeftPosition);
  }

  return (
    <div className="relative flex h-56 w-full justify-between px-3">
      <button onClick={handleMoveBackward} className="text-2xl">
        <FaAngleLeft />
      </button>
      <div className=" absolute left-0 -z-10 h-full w-full overflow-hidden">
        <div
          className="absolute flex  h-full transition-all"
          style={{
            width: `${containerWidthPercent}%`,
            left: `${leftPosition}px`,
          }}
        >
          {partnerList.map((e, idx) => (
            <PartnerCard
              partnerRef={partnerCardRef}
              imgUrl={e.imgSrc}
              partnerName={e.partnerName}
              cardWidthPercent={cardWidthPercent}
              key={idx}
            />
          ))}
        </div>
      </div>
      <button onClick={handleMoveForward} className="text-2xl">
        <FaAngleRight />
      </button>
    </div>
  );
}

function PartnerCard({
  imgUrl,
  partnerName,
  partnerRef,
  cardWidthPercent,
}: {
  imgUrl: string;
  partnerName: string;
  partnerRef?: React.RefObject<HTMLAnchorElement>;
  cardWidthPercent: number;
}) {
  return (
    <article
      className="flex h-full flex-col gap-1"
      style={{ width: `${cardWidthPercent}%` }}
      ref={partnerRef}
    >
      <div className="flex h-5/6 w-full items-center justify-center">
        <img src={imgUrl} className=" max-h-full max-w-full" />
      </div>
      <p className="grow">{partnerName}</p>
    </article>
  );
}

export default OurPartner;
