import clsx from "clsx";
import React, { ReactNode, useEffect, useState } from "react";

function FadeAnimator({
  className,
  duration,
  children,
}: {
  className?: string;
  duration?: number;
  children: React.ReactNode;
}) {
  const [lastChildren, setLastChildren] = useState(children);
  const [currChildren, setCurrChildren] = useState(children);

  const [showFrame, setShowFrame] = useState(0);

  useEffect(() => {
    setLastChildren(currChildren);
    setCurrChildren(children);
    setShowFrame(showFrame == 0 ? 1 : 0);
  }, [children]);

  return (
    <div className={clsx("relative", className)}>
      <Frame isAbsolute={true} isShow={showFrame == 0}>
        {showFrame == 0 ? currChildren : lastChildren}
      </Frame>
      <Frame isAbsolute={false} isShow={showFrame == 1}>
        {showFrame == 1 ? currChildren : lastChildren}
      </Frame>
    </div>
  );
}

function Frame({
  duration,
  isAbsolute,
  isShow,
  children,
}: {
  duration?: number;
  isAbsolute: boolean;
  isShow: boolean;
  children: ReactNode;
}) {
  return (
    <div
      className={clsx(
        isShow ? "opacity-100" : "opacity-0",
        isAbsolute && "absolute left-0 top-0",
        `transition-opacity duration-${duration ?? 500} w-full`
      )}
    >
      {children}
    </div>
  );
}

export default FadeAnimator;
