import { useTranslation } from "react-i18next";
import PageTemplate from "../components/PageTemplates.tsx";
import React from "react";

function Entrepreneurship() {
  const { t, i18n } = useTranslation();

  return (
    <PageTemplate
      title={t("starting a business")}
      subtitle={t("careerList")}
      type="DESIGNER_ENTREPRENEURSHIP_ADVICE"
    />
  );
}

export default Entrepreneurship;
