import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";

type MediaType = {
  url: string;
  type: "image" | "video";
};

function Carousel({
  mediaList,
  numPageItem,
  scrollTo,
  onMediaSelect,
  className,
}: {
  mediaList: MediaType[];
  numPageItem: number;
  scrollTo: "forward" | "backward" | "initial";
  onMediaSelect: (mediaIdx: number) => void;
  className?: string;
}) {
  let itemWidth = `${(1 / numPageItem) * 100}%`;
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let carousel = carouselRef.current;
    if (carousel == null || scrollTo === "initial") return;

    let carouselWidthPx = carouselRef.current!!.offsetWidth;
    let itemWidthPx = carouselWidthPx * (1 / numPageItem);

    let scrollPx = numPageItem * itemWidthPx;
    scrollPx = scrollTo === "forward" ? -scrollPx : scrollPx;
    carousel.scrollBy({ left: scrollPx });
  }, [scrollTo]);

  return (
    <div
      className={clsx("carousel carousel-end rounded-box", className)}
      ref={carouselRef}
    >
      {mediaList.map((e, idx) => (
        <CarouselItem
          media={e}
          width={itemWidth}
          onMediaSelect={() => onMediaSelect(idx)}
          key={idx}
        />
      ))}
    </div>
  );
}

function CarouselItem({
  media,
  width,
  onMediaSelect,
  objectFit,
}: {
  media: MediaType;
  width: string;
  onMediaSelect?: any;
  objectFit?: "contain" | "cover";
}) {
  return (
    <button
      className="carousel-item h-full "
      onClick={onMediaSelect}
      style={{ width: width }}
    >
      {media.type === "image" ? (
        <img
          src={media.url}
          alt="gallery photo"
          className={clsx("h-full w-full object-contain object-center")}
        />
      ) : (
        <video className={clsx("h-full w-full object-contain object-center")}>
          <source
            src={media.url}
            type={`video/${media.url.split(".").pop()}`}
          />
        </video>
      )}
    </button>
  );
}

export default Carousel;
export { type MediaType };
