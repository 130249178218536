import { useTranslation } from "react-i18next";
import PageTemplate from "../components/PageTemplates.tsx";
import React from "react";

function CooperationSuggestion() {
  const { t, i18n } = useTranslation();

  return (
    <PageTemplate
      title={t("cooperation")}
      subtitle={t("cooperationList")}
      type="COOPERATION_SUGGESTION"
    />
  );
}

export default CooperationSuggestion;