import React, { useEffect, useRef, useState } from "react";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";

function Pagination({
  totalPage,
  currPage,
  onPageChange,
}: {
  totalPage: number;
  currPage: number;
  onPageChange: (p: number) => void;
}) {
  const [isInput, setIsInput] = useState(false);
  const [inputPage, setInputPage] = useState<string>(currPage.toString());
  const inputRef = useRef<HTMLInputElement>(null);

  function handleInputPageChange(newPageStr: string) {
    if (newPageStr === "") {
      setInputPage(newPageStr);
      return;
    }
    let newPage = parseInt(newPageStr);
    newPage = newPage > totalPage ? totalPage : newPage < 1 ? 1 : newPage;
    setInputPage(newPage.toString());
  }

  function handlePageChange(newPageStr: string) {
    if (newPageStr === "") {
      setInputPage(currPage.toString());
      return;
    }

    let newPage = parseInt(newPageStr);
    if (newPage == currPage) return;

    onPageChange(newPage);
  }

  function handleInputKeyDown(e: React.KeyboardEvent) {
    if (e.key === "Enter") {
      inputRef.current?.blur();
    }
  }

  function handleInputBlur() {
    handlePageChange(inputPage);
    setIsInput(false);
  }

  return (
    <div className="flex justify-center gap-3">
      <button onClick={() => onPageChange(1)}>
        <FaAngleDoubleLeft />
      </button>
      <button
        onClick={() =>
          onPageChange(currPage == 1 ? 1 : currPage - 1)
        }
      >
        <FaAngleLeft />
      </button>
      <div className="flex gap-1">
        <span>Page</span>
        {!isInput && (
          <span className="cursor-text" onClick={() => setIsInput(true)}>
            {currPage}
          </span>
        )}
        {isInput && (
          <input
            type="number"
            value={inputPage}
            className="w-full shrink rounded border border-gray-300 font-bold"
            onChange={(e) => handleInputPageChange(e.target.value)}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
            ref={inputRef}
          />
        )}
        <span>of</span>
        <span className="font-bold">{totalPage}</span>
      </div>
      <button onClick={() => onPageChange(currPage == totalPage ? totalPage : currPage + 1)}>
        <FaAngleRight />
      </button>
      <button onClick={() => onPageChange(totalPage)}>
        <FaAngleDoubleRight />
      </button>
    </div>
  );
}

export default Pagination;
