import React, { useEffect, useState } from "react";
import FadeAnimator from "../../../component/Animator/FadeAnimator.tsx";
import clsx from "clsx";
import { Link } from "react-router-dom";

const MAXITEM = 10;

function ActivityHistory() {
  let dataDemo = [
    {
      date: "2023年1月",
      imgSrc: "image/actionHistory_img1.png",
      text: "築航天・科技夢 - 四川・西昌衛星發射中心航太科技主題2023夏令營第一期已順利完成",
    },
    {
      date: "2023年2月",
      imgSrc: "image/actionHistory_img2.png",
      text: "築航天・科技夢 - 四川・西昌衛星發射中心航太科技主題2023夏令營第二期已順利完成",
    },
  ];

  const [data, setData] = useState([]);
  let [currData, setCurrData] = useState(0);

  // Auto swap data
  const autoSwap = true;
  const interval = 5000;
  useEffect(() => {
    if (!autoSwap) return;
    let id = setTimeout(handleNextData, interval);

    return () => clearTimeout(id);
  }, [currData]);

  useEffect(() => {
    async function load() {
      const res = await fetch(`https://yja.p360tec.com/api/content/getList-article-client?pages=1&size=${MAXITEM}&type=PAST_ACTIVITY`);
      const json = await res.json();
      //console.log(json);
      const result = json.result.objects?.[0] ?? [];
      setData(
        result.map((d) => ({
          activityId: d.a_id,
          date: d.date,
          imgSrc: d.cover,
          text: d.title,
        }))
      );
    }
    load();
  }, []);

  function handlePrevData() {
    let newCurrData = currData - 1 < 0 ? data.length - 1 : currData - 1;
    setCurrData(newCurrData);
  }
  function handleNextData() {
    let newCurrData = currData + 1 > data.length - 1 ? 0 : currData + 1;
    setCurrData(newCurrData);
  }

  const timeLineItemList: JSX.Element[] = [];
  if (data != null) {
    for (let i = 0; i < data.length && i < 3; ++i) {
      timeLineItemList.push(
        <TimeLineItem
          number={(currData + i) % data.length}
          data={data[(currData + i) % data.length]}
          className={clsx(i !== 0 ? "hidden" : "flex", i === 1 && "md:flex", i === 2 && "lg:flex")}
          key={i}
        />
      );
    }
  }

  return (
    <div className="-mb-24 flex w-11/12 flex-col items-center border-t border-gray-400 pt-10">
      <h2 className="mb-16 text-2xl font-bold md:text-4xl">活動歷史</h2>

      {/*
      <div className="mb-8 flex  w-full flex-col text-start">
        <p>花絮</p>
        <p>未來活動</p>
      </div>
  */}
      <TimeLine onPrevData={handlePrevData} onNextData={handleNextData} />
      <div className=" flex w-[80%] -translate-y-[105px] gap-3 md:-translate-y-[130px]">{timeLineItemList}</div>
    </div>
  );
}

function TimeLine({ onPrevData, onNextData }) {
  return (
    <div className="relative flex w-full items-center justify-between overflow-hidden py-3">
      <img src="image/Keyboard_arrow_left.svg" className="h-12 w-12 cursor-pointer md:h-16 md:w-16" onClick={onPrevData} />
      <img src="image/Keyboard arrow right.svg" className="h-12 w-12 cursor-pointer md:h-16 md:w-16" onClick={onNextData} />
      <div className="absolute top-1/2 -z-10 w-full border-t-2"></div>
    </div>
  );
}

type ImageGalleryProps = {
  number: number;
  data?: {
    activityId: string;
    date: string;
    imgSrc: string;
    text: string;
  };
  className?: string;
};

function TimeLineItem({ number, data, className }: ImageGalleryProps) {
  return (
    <Link to={`/past_activity/${data?.activityId}`} className={`${className} flex-1`}>
      <FadeAnimator className={`flex flex-col items-center gap-4 `}>
        <p className=" text-xl md:text-3xl">{data?.date}</p>
        <div className={`flex h-12 w-12 items-center justify-center rounded-full bg-[#B2222C] text-center text-white md:h-16 md:w-16 `}>{number + 1}</div>
        <div className="flex flex-col gap-2">
          <div className="flex h-36 items-center justify-center">
            <img src={data?.imgSrc} className="h-full w-auto" />
          </div>
          <p className="w-80%">{data?.text}</p>
        </div>
      </FadeAnimator>
    </Link>
  );
}

export default ActivityHistory;
