import React, { useEffect, useState } from "react";
import Footer from "../../../component/Footer.tsx";
import Navbar from "../../../component/Navbar.tsx";
import ActivityLink, { ActivityLinkType } from "../components/ActivityLink.tsx";
import Pagination from "../../../component/Pagination.tsx";

const numPageItem = 5;

export default function ActivityListTemplate({
  type,
  title,
}: {
  type: "NEWEST_ACTIVITY" | "PAST_ACTIVITY" | "FUTURE_ACTIVITY";
  title: string;
}) {
  const [dataList, setDataList] = useState<ActivityLinkType[] | null>([]);
  const [currPage, setCurrPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const urlPrefix = {
    NEWEST_ACTIVITY: "/latest_activity",
    PAST_ACTIVITY: "/past_activity",
    FUTURE_ACTIVITY: "/future_activity",
  };

  useEffect(() => {
    async function init() {
      let result = await loadData(currPage);
      setTotalPage(result.result.total);
    }
    init();
  }, []);

  function handlePageChange(newPage: number) {
    setCurrPage(newPage);
    loadData(newPage);
  }

  async function loadData(newPage: number) {
    const res = await fetch(
      `https://yja.p360tec.com/api/content/getList-article-client?pages=${newPage}&size=${numPageItem}&type=${type}`
    );
    const result = await res.json();
    //console.log(result);
    if (result.code !== "2000") return;

    const rawArticleList = result.result.objects?.[0] ?? [];
    let newDataList = rawArticleList.map((e) => {
      return {
        title: e.title,
        imgUrl: e.cover,
        date: e.date,
        href: `${urlPrefix[type]}/${e.a_id}`,
      };
    });
    setDataList(newDataList);
    return result;
  }
  return (
    <>
      <Navbar />
      <main className="mx-auto flex w-4/5 max-w-7xl flex-col gap-10">
        <div className="flex justify-center text-4xl font-bold">{title}</div>
        <div className="flex flex-col gap-3">
          {dataList?.map((e) => (
            <ActivityLink
              href={e.href}
              imgUrl={e.imgUrl}
              title={e.title}
              date={e.date}
            />
          ))}
        </div>
        <Pagination
          currPage={currPage}
          totalPage={totalPage}
          onPageChange={handlePageChange}
        />
      </main>
      <Footer />
    </>
  );
}
